/* General styles */
:root {
  --primary-color: #007bff;
  --secondary-color: #28a745;
  --error-color: #dc3545;
  --text-color: #333;
  --background-color: #f9f9f9;
  --border-color: #ccc;
  --hover-color: #0056b3;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
}

.export-button ,.add-customer ,.admin-button {
  width: 150px;
}

/* Container styles */
.container {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.header input {
  margin: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 7px;
}

h2 {
  color: var(--background-color);
  width: 100%;
  padding: 20px 0;
  margin: 0 0;
  text-align: center;
  border: 1px solid var(--border-color);
  background-color: #28a745;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #333;
}

.namesform input {
  width: 30%;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 18px;
  transition: border-color 0.3s;
}

.searchinput {
  width: 30%;
  margin: 7px 0;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 18px;
  transition: border-color 0.3s;
}

/* Appointment Schedule styles */
.Appointment-Schedule-header{
  margin-top: 100px;
}
.appointment-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0;
  
}
.appointment-table input, select  {
    width: 90px;
    padding: 3px;
    margin: 3px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 12px;
    transition: border-color 0.3s;
    text-align: center;
    
}

input:focus, select:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Highlight selected customer */
.selected-color {
  background-color: #cce5ff; /* Light blue background */
}


/* Searchable dropdown styling */
.dropdown {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 10;
  list-style: none;
  padding: 0;
}

.dropdown-item {
  padding: 8px;
  color: #333;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #cce5ff; /* Highlight on hover */
}

.show-button {
  margin: 5px 0;
}
.hide-all-cells {
  font-size: 14px;
  padding: 7px;
  margin: 7px;
  background-color: #f0f8ff;
  color: #28a745;
}
/* Button styles */
button {
  padding: 12px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--hover-color);
}

.appointment-table th,td {
  border: 1px solid var(--border-color);
  padding: 7px 3px;
  text-align: center;
  transition: background-color 0.3s;
  color: white;
  font-size: 16px; 
  width: 100%;
}

.appointment-table tr {
  display: flex;
}

.appointment-table th {
  background-color: var(--secondary-color);
}

.appointment-table tbody .td1 {
  background-color: var(--secondary-color);
}

.appointment-table .td2  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}


/* Scrollable table styles */
.scrollable-table {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 50px;
  background-color: white;
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table-header, .table-row {
  display: flex;
  width: 100%;
}

.table-header-item,
.table-item {
  flex: 1;
  padding: 12px;
  border: 1px solid var(--border-color);
  text-align: left;
  box-sizing: border-box;
}

.table-header {
  background-color: var(--secondary-color);
  color: white;
}

.table-row {
  transition: background-color 0.3s;
}

.table-row:hover {
  background-color: #f0f8ff;
}

/* Button styles for edit/remove */
.edit-button, .remove-button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.edit-button {
  background-color: var(--primary-color);
  color: white;
}

.remove-button {
  background-color: var(--error-color);
  color: white;
}

.edit-button:hover {
  background-color: var(--hover-color);
}

.remove-button:hover {
  background-color: #c82333;
}

/* Responsive styling for screens smaller than 768px */
@media (max-width: 768px) {
  /* Hide default table structure on small screens */
  .appointment-table thead {
      display: none;
  }

  /* Style rows to display as blocks */
  .appointment-table tbody tr {
      display: flex;
      margin: 10px 0;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
  }

  .appointment-table tbody td {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 3px;
      width: 100%;
  }

  /* Add labels for each cell, simulating the header on small screens */
  .appointment-table tbody td::before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
      color: #555;
  }
  
  .appointment-table .td1 {
    width: 140%;
  }

  /* Style for buttons and inputs to fit better on mobile */
  .show-button {
      text-indent: -9999px; 
      width: 100%;
      height: 35px;
      padding: 0;
      margin: 10px 0;
  }

  .appointment-table input, select  {
    width: 190px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
}
  
}