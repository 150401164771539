/* Header.css */
.header {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-list li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-list li a:hover {
  color: #f0f8ff;
}


